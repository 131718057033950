<template>
    <v-main>
		<v-container fluid fill-height>
			<v-row align-center justify-center>
				<v-col xs12 sm8 md4>
					<v-card>
						<v-toolbar dark>
							<v-toolbar-title>Reset my password</v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<v-form>
								<v-text-field name="Mot de Passe" label="Password" type="password" v-model="passwordReset"></v-text-field>
								<v-text-field name="Mot de Passe" label="Password confirmation" type="password" v-model="passwordReset2"></v-text-field>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn text @click.prevent="TestData()">
                        		Create account
                    		</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-main>
</template>

<script>
	import bcrypt from 'bcryptjs';
	export default {
		name: 'Login',
		data(){
			return {
                errors: [],
				passwordReset: '',
				passwordReset2: '',
				tokenEmail: '',
				tokenDB: '',
				id: '',
			}
		},

		mounted(){
            this.getEmailToken();
		},

		methods: {	
			TestData(){
				console.log(
					this.passwordReset,
					this.passwordReset2,
				)
				if(!this.passwordReset || !this.passwordReset2){
					this.errors.push("please enter all fields")
				}
				if(this.passwordReset != this.passwordReset2){
					this.errors.push( "Different passwords")
				}
				if(!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,20}$/.test(this.passwordReset)){
					this.errors.push( "The password is not in the right format")
				}
				if(this.errors.length > 0){
					console.log(this.errors)
				}else{
					const PaswwordReseted = Promise.all([this.resetPassword()]);
					if(PaswwordReseted){

					}else{
						window.alert('error')
					}
				}
				this.errors = [];
				return
			},

            async getEmailToken(){
				const url = window.location.href;
				
				const tokenwithid = url.split('\?')
				console.log(tokenwithid)
				const tokensplit = tokenwithid[1].split('id=')
				this.tokenEmail  = tokensplit[0];
				this.id = tokensplit[1];
				console.log('id = ' + this.id)
				console.log('token = ' + this.tokenEmail)
			},

			async getToken(){
				const id = encodeURIComponent(this.id);
				const url = this.$api.getRESTApiUri() + `/users/${id}`;
				return fetch(url)
				.then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
					this.tokenDB = data.token
					console.log(this.tokenDB)
				})
				.catch((error) => {
					console.log(error)
				});
			},

			async checkToken(){
				await Promise.all([this.getToken()]);
				console.log(this.tokenDB)
				console.log(this.tokenEmail)
				if(this.tokenDB == this.tokenEmail){
					this.TestData();
					return true;
				}else{
					window.alert('Invalid Token')
					return false;
				}
			},

			async resetPassword(){
				const salt = bcrypt.genSaltSync(10);
				let PasswordHash = bcrypt.hashSync(this.passwordReset, salt);
				const url = this.$api.getRESTApiUri() + `/reset/password`
				fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							id: this.id,
							password: PasswordHash,
						}),
				})
			// Converting to JSON
			.then(response => response.json())
			// Displaying results to console
			.then(json => console.log(json));		
			},
		}
	}

</script>